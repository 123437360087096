@use 'clear-fix';
@use 'colors';
@use 'fonts';
@use 'buttons';
@use 'config';
@import "include-media";

body {
  margin: 0;
  font-family: fonts.$montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: config.$rem;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: colors.$colorDark;
  color: colors.$colorCopy150;
  line-height: 1.62;
}

body.blur {
  overflow: hidden;

  main {
    filter: blur(5px) brightness(.7);
  }
}

body::-webkit-scrollbar {
  width: 12px;
  @include media(">phone") {
    width: 6px;
  }
}

body::-webkit-scrollbar-track {
  background-color: colors.$colorDark;
}

body::-webkit-scrollbar-thumb {
  background-color: colors.$colorCopy200;
  border: 3px solid colors.$colorCopy200;
  border-radius: 10px;

  @include media(">phone") {
    background-color: colors.$colorCopy300;
    border: 1px solid colors.$colorCopy300;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.numberedHeading {
  color: colors.$colorCopy100;
  display: flex;
  align-items: center;
  position: relative;
  margin: .6rem 0 2.5rem;
  width: 100%;
  white-space: nowrap;

  &:before {
    position: relative;
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: .6rem;
    color: colors.$colorAccent;
    font-weight: 400;
    font-size: clamp(1.2rem, 3vw, 1.3rem);
  }

  &:after {
    margin-left: .625rem;
    display: block;
    content: "";
    position: relative;
    height: 1px;
    width: 100%;
    background-color: colors.$colorMuted;

    @include media(">tablet") {
      width: 18.75rem;
    }
  }
}

main {
  margin: 0 2.5rem;

  @include media(">tablet") {
    margin: 0 10rem;
    max-width: config.$maxWidth;
  }
}