@use 'config';
@use 'colors';
@import "../../node_modules/include-media";


.headShot {
  max-width: 300px;
  width: 70%;
  margin: 4rem auto 0;


  @include media(">tablet") {
    margin: 0 0 0 4rem;
  }

  .imageWrapper {
    transition: config.$transition;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: config.$borderRadius;
    background-color: colors.$colorAccent;

    @include media(">tablet") {
      width: 300px;
      height: 300px;
    }

    &:hover {
      &:after {
        top: 0;
        left: 0;
        visibility: hidden;
      }
      div {
        filter: none;
        mix-blend-mode: normal;
      }
    }


    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: colors.$colorDark;
      border-radius: config.$borderRadius;
      mix-blend-mode: screen;

      @include media(">tablet") {
        width: 300px;
        height: 300px;
      }
    }

    &:after {
      content: "";
      display: block;
      max-width: 300px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: -1;
      visibility: visible;
      border: 2px solid colors.$colorAccent;
      border-radius: config.$borderRadius;
      transition: config.$transition;

      @include media(">tablet") {
        width: 300px;
        height: 300px;
      }
    }

    div {
      position: relative;
      border-radius: config.$borderRadius;
      filter: grayscale(100%) contrast(1);
      mix-blend-mode: multiply;
      transition: config.$transition;
      object-fit: cover;
      width: 100%;
      height: 100%;

      @include media(">tablet") {
        width: 300px;
        height: 300px;
      }
    }

    img {
      display: block;
      border-radius: config.$borderRadius;
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-width: 300px;

      @include media(">tablet") {
        width: 300px;
        height: 300px;
      }
    }
  }
}
