@use 'config';
@use 'colors';
@import "../../node_modules/include-media";

#mobileMenu {
  @include media(">tablet") {
    display: none;
  }
}

#menuToggle {
  z-index: 10;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 1rem;
  text-transform: none;
  transition-duration: .15s;
  transition-property: opacity, filter;
  color: inherit;
  border: 0;
  margin-right: -1rem;
  background-color: transparent;


  .ham-box {
    display: inline-block;
    position: relative;
    width: config.$hamburgerWidth;
    height: 24px;

    .ham-box-inner {
      position: absolute;
      top: 50%;
      right: 0;
      width: config.$hamburgerWidth;
      height: 2px;
      border-radius: 4px;
      background-color: colors.$colorAccent;
      transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
      transform: rotate(0deg);

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: auto;
        right: 0px;
        top: -10px;
        width: 120%;
        height: 2px;
        border-radius: 4px;
        background-color: colors.$colorAccent;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: auto;
        right: 0px;
        bottom: -10px;
        width: 80%;
        height: 2px;
        border-radius: 4px;
        background-color: colors.$colorAccent;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
      }
    }
  }
}

#menuToggle.sidebarOpen {
  .ham-box-inner {
    position: absolute;
    width: config.$hamburgerWidth;
    height: 2px;
    background-color: colors.$colorAccent;
    top: 50%;
    border-radius: 4px;
    right: 0;
    transition: transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(225deg);

    &:before {
      width: 100%;
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    }

    &:after {
      width: 100%;
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    }
  }
}

#menuToggle.sidebarOpen {
  .ham-box-inner {
    position: absolute;
    width: config.$hamburgerWidth;
    height: 2px;
    background-color: colors.$colorAccent;
    top: 50%;
    right: 0;
    transition: transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(225deg);
  }
}

aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: min(75vw, 400px);
  background-color: colors.$colorSideBar;
  z-index: 9;
  transform: translateX(0vw);
  transition: config.$transition;
  visibility: hidden;

  nav {
    display: flex;
    padding: 0;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    ol {
      margin: 0;
      width: 100%;
      list-style: none;
      padding: 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      outline: 0;

      li {
        display: list-item;
        margin: 1rem auto 1rem;
        position: relative;
        counter-increment: item 1;

        a {
          &:before {
            content: "0" counter(item) ".";
            display: block;
            margin-bottom: .5rem;
            margin-right: 5px;
            color: colors.$colorAccent;
          }
        }
      }
    }

    .buttonPrimarySmall {
      margin-left: 0;
      padding: 1rem 1.25rem;
    }
  }
}

aside.sidebarClosed {
  visibility: hidden;
  transform: translateX(100vw);
}

aside.sidebarOpen {
  visibility: visible;
  transform: translateX(0vw);
}