$colorDark: #0B0D0F;
$colorSideBar: #0f1215;
$colorMuted: #708CA9;
$colorAccent: #8BE9FD;
$colorAccentTint: rgba(139, 233, 253, .2);

$colorCopy100: #D9D9D9;
$colorCopy150: #859db6;
$colorCopy200: #708CA9;
$colorCopy300: #414D58;
