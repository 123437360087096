$maxWidth: 1020px;
$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
$rem: 16px;
$borderRadius: 8px;

// fonts
$xxsm: .75rem;
$xsm: .8125rem;
$sm: .875rem;
$md: 1rem;
$lg: 1.125rem;
$xl: 1.25rem;
$xxl: 1.375rem;


$hamburgerWidth: 2rem;