@use 'colors.scss';
@use 'fonts.scss';
@use 'config.scss';

$buttonBorderRadius: 8px;

.buttonPrimarySmall,
.buttonPrimary {
  display: inline-block;
  color: colors.$colorDark;
  font-weight: 400;
  background-color: colors.$colorAccent;
  border: 1px solid colors.$colorAccent;
  border-radius: $buttonBorderRadius;
  font-size: 1rem;
  font-family: var(--font-mono);
  align-items: center;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: config.$transition;
  padding: .75rem 1rem;

  &:hover,
  &:focus,
  &:active {
    background-color: colors.$colorAccentTint;
    color: colors.$colorAccent;
    font-weight: 400;
    outline: none;
  }

  &:after {
    display: none !important;
  }
}

.buttonPrimary {
  padding: 1rem 1.25rem;
}

.borderless {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  font-size: 1rem;

  &:hover,
  &:active,
  &:focus {
    color: colors.$colorAccent;
  }
}

.imageLink {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  font-size: 1.2rem;
  stroke: colors.$colorMuted;

  svg {
    vertical-align: middle;
    stroke: colors.$colorMuted;
    stroke-width: 1px;
    width: 24px;
    height: 24px;

  }

  &:hover,
  &:active,
  &:focus {
    transform: translateY(-5px);

    svg {
      stroke: colors.$colorAccent;
    }
  }
}

