@use 'config';
@use 'colors';
@import "../../node_modules/include-media";


.logo {
  height: 3rem;
  pointer-events: none;
}

header {
  background-color: colors.$colorDark;

  @include media(">tablet") {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  nav {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;

    * {
      display: flex;
      align-items: center;
    }

    a.buttonPrimarySmall {
      margin-left: 1rem;
    }
  }

}

header nav div.navLinks {
  display: none;

  @include media(">tablet") {
    display: flex;
  }
}

.navLinks {
  ol {
    list-style: none;

    li {
      display: list-item;
      margin: 0 5px;
      position: relative;
      counter-increment: item 1;

      a {
        &:before {
          content: "0" counter(item) ".";
          margin-right: 5px;
          color: colors.$colorAccent;
        }
      }
    }
  }
}

