@use './colors';
@use 'fonts';
@import "../../node_modules/include-media";

#hero {
  text-align: left;
  position: relative;

  @include media(">tablet") {
    height: 100vh;
    max-width: 2080px;
  }

  .content {
    padding-top: 4rem;
    @include media(">tablet") {
      position: absolute;
      padding: 0;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }


  h1 {
    color: colors.$colorAccent;

    font-size: 1rem;
    margin-left: .2rem;

    @include media(">tablet") {
      font-size: 1.2rem;
      margin-left: .2rem;
    }
  }

  h2 {
    color: colors.$colorCopy100;
    font-size: 2.2rem;
    line-height: 2.9rem;

    @include media(">tablet") {
      font-size: 4rem;
    }
  }

  h3 {
    color: colors.$colorCopy300;
    font-size: 2.2rem;
    line-height: 2.7rem;

    @include media(">tablet") {
      font-size: 3.5rem;
      line-height: 4.3rem;
    }
  }

  p {
    line-height: 1.8rem;

    @include media(">tablet") {
      max-width: 70%;
    }
  }

  h1 + h2 {
    margin-top: 1rem;
  }

  h2 + h3 {
    margin-top: 1rem;
  }

  .actionGroup {
    display: flex;
    align-items: normal;
    flex-direction: column;
    justify-content: center;

    margin: 2rem 0;

    @include media(">tablet") {
      margin: 2rem 4rem;
      flex-direction: row;
    }
  }

  .headShot {
    margin: auto auto;

    @include media(">tablet") {
      margin: 0;
    }
  }

  .resume {

    margin: 3rem auto 0 auto;
    @include media(">tablet") {
      display: block;
      margin: 2rem 4rem;
    }
  }
}