@use 'config';
@use 'colors';
@import "../../node_modules/include-media";

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;

  @include media(">tablet") {
    min-height: 4rem;
  }
}

ul.socialLinks {
  display: flex;
  margin: auto auto;
  max-width: 200px;
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @include media(">tablet") {
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 2rem;

    &:after {
      content: "";
      margin: 2rem 1rem 0 1rem;
      display: block;
      height: 8rem;
      width: 1px;
      background-color: colors.$colorMuted;
    }
  }

  a {
    display: inline-block;
    padding: .75rem;
  }
}