@use 'config';
@use 'colors';
@import "../../node_modules/include-media";

#about {
  .content {
    padding-bottom: 4rem;
    @include media(">tablet") {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .copy {
        max-width: 80%;
      }
    }

    .tech {
      ul {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, minmax(140px, 200px));
        gap: 0 10px;
        margin: 1rem 0 0;
        list-style: none;

        @include media(">tablet") {
          grid-template-columns: repeat(3, minmax(140px, 200px));
        }

        li {
          position: relative;
          margin-bottom: .625rem;
          padding-left: 1.25rem;
          font-size: config.$xsm;
          display: list-item;

          &:before {
            content: "▹";
            position: absolute;
            left: 0px;
            color: colors.$colorAccent;
            font-size: config.$sm;
          }
        }
      }
    }
  }
}